import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
//HTTP Requests
import axios from "axios";
//Validación de Formularios
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, decimal } from 'vee-validate/dist/rules';
//Notificaciones
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
//Fuentes e Icónos
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
//import '@babel/polyfill'
//Formatos para números
import Vue2Filters from 'vue2-filters'
//Parse CSV a Objectos
import VuePapaParse from 'vue-papa-parse'
//Exportar JSON a CSV
import VueJsonToCsv from 'vue-json-to-csv'
//RBAC CASL
import { abilitiesPlugin, subject, Can } from '@casl/vue';


Vue.component('Can', Can);
import ability from './config/ability';

Vue.config.productionTip = false

//Plugin para notificaciones 
Vue.use(Notifications, { velocity })

//Plugin para formato de fecha
const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
    moment
})

//Filtros para formato
Vue.use(Vue2Filters)

//Parse CSV a JSON
Vue.use(VuePapaParse)

// JSON a CSV
Vue.use(VueJsonToCsv)


Vue.component('Confirm', () =>
    import ('./components/alerts/Confirm.vue'));

Vue.component('ShowWorkOrder', () =>
    import ('./components/production/ShowWorkOrder.vue'));

//API Base URL


//export const API_BASE_URL = "http://localhost:8000/api/"
//export const API_BASE_URL = "https://creativeline.runflow.cl/api/";
//export const API_BASE_URL = "http://" + location.hostname + "/api/";
export const API_BASE_URL = "https://" + location.hostname + "/api/";

axios.defaults.baseURL = API_BASE_URL;

//Using Form Validation - Vee-Validate
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('required', {
    ...required,
    message: 'Este campo es requerido'
});
extend('email', {...email, message: 'El email no es válido' });
extend('max', {validate(value,args){
    return value.length<=args.length;},
    params:['length'],
    message: 'El campo debe tener una longitud máxima de {length}'
});
extend('minvalue', {validate(value,args){
    return value>=args.min_value;},
    params:['min_value'],
    message: 'El campo debe tener un valor mínimo de {min_value}'
});

Vue.use(abilitiesPlugin, ability, subject, {
    useGlobalProperties: true
})

const parameters=store.getters.getParameters;


import {moneda} from './utils/filtros.js';
import { max } from 'moment';

Vue.filter('moneda',value=>moneda(value));

/* 
Con esta función se regresa a Auth Login cada vez que actualizo la página
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('access_token')
    const user = store.getters.getUser
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (token && user) {
            next()
        } else {
            next('/auth/login')
        }
    } else next();
}); */

//store.dispatch('getMe')

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')