import moment from 'moment'
import { fetchCompany } from '@/api/parameters'
var numeral = require('numeral');

moment.locale('es');

export async function pdfPurchaseOrder(content) {
    var pdfMake = require("pdfmake/build/pdfmake.js");
    var pdfFonts = require("pdfmake/build/vfs_fonts.js");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    //Trayendo datos de la compañía
    const company = await fetchCompany()

    //Estableciendo la fecha de emisión
    const created_at = moment(content.created_at).format('DD-MMM-YYYY');

    //configuración de formado de números 
    const numberFormat = 'de-DE'
    const currencyFormat = 'CLP'
    console.log(content)
    const IVA = content.iva

    //Cálculo de IVA y Total con el IVA
    const neto = content.details.reduce((acc, val) => { return acc + val.qty * val.price; }, 0) 
    const discount = neto * parseFloat(content.discount)*0.01
    const subTotal = neto - discount
    const totalIVA = subTotal * IVA*0.01
    const totalconIVA = subTotal + totalIVA

    //Estableciendo Artículo o Servicio
    const stock = content.stock_service

    console.log(company)
    console.log(content.details)

    pdfMake.fonts = {
        Poppins: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-SemiBold.ttf"
        },
        RobotoCondensed: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Bold.ttf"
        }
    };

    var dd = {
        pageSize: "LETTER",
        content: [

            { //Logo 
                image: 'logo',
                width: 120,
                margin: [0, 0, 0, 10]
            },
            //Encabezado esquina superior izquierda
            { text: company.name },
            { text: company.giro },
            { text: company.address },
            { text: 'Fono: ' + company.phone + ' - ' + company.email + ' - ' + company.webpage },
            { text: 'Fecha: ' + created_at, alignment: 'right', margin: [0, 20, 0, 0] },
            //Encabezado esquina superior derecha
            {
                absolutePosition: { x: 395, y: 50 },
                margin: [0, 10, 0, 0],
                layout: 'noBorders',
                table: {
                    widths: [170, ],
                    heights: [25, 10, 40, 10, 10],
                    body: [
                        //[{ text: 'RUT.: ' + company.rut, alignment: 'center', fontSize: 12, color: 'red' }, ],
                        [{ text: 'Orden de Compra', alignment: 'center', fontSize: 20, bold: true, }, ],
                        [{ text: 'Nro. ' + content.id, alignment: 'center', fontSize: 20, bold: true, }, ],
                    ]
                }
            },
            //Creado Por 
            {
                
                text:'Creada por: ' + content.created_by_full_name,
                absolutePosition: { x: 395, y: 150 },
                margin: [0, 10, 0, 0],
            },

            //Dibujos en Canvas
            { //Cuadro Rojo esquina superior derecha
                absolutePosition: { x: 390, y: 45 },
                canvas: [{
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 180,
                    h: 100,
                    r: 1,
                    lineWidth: 3,
                    lineColor: 'red',
                    color: '',
                }, ]
            },

            // Encabezado centro
            {
                layout: 'noBorders',
                table: {
                    widths: ['11%', '50%', '20%', '19%'],
                    body: [
                        [
                            { text: 'Señor(es):', alignment: 'left' },
                            { text: content.provider_name, alignment: 'left', bold: true, },
                            { text: '', alignment: 'left' },
                            { text: '', alignment: 'center' },
                        ],
                        [
                            { text: 'RUT:', alignment: 'left' },
                            { text: content.provider_rut, alignment: 'left', bold: true, },
                            { text: 'Moneda:', alignment: 'right' },
                            { text: content.moneda, alignment: 'left', bold: true, },
                        ],
                        [
                            { text: 'Referencia:', alignment: 'left' },
                            { text: content.title, alignment: 'left', bold: true, },
                            { text: 'Condición de Pago:', alignment: 'right' },
                            { text: content.payment == 0 ? 'Contado' : `${content.payment} días`, alignment: 'left', bold: true, },
                        ],
                    ]
                }
            },
            //Tabla con Items a Cotizar Función
            {
                layout: 'lightHorizontalLines',
                style: 'table',
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['6%', '50%', '10%', '17%', '17%'],
                    body: buildItemsTableBody(content.details, stock)
                }
            },
            //Sección Observaciones
            {
                margin: [0, 10, 0, 0],
                layout: 'noBorders',
                table: {
                    widths: ['15%', '85%'],
                    body: [
                        [
                            { text: 'Observaciones:', alignment: 'left' },
                            { text: content.notes, alignment: 'left' },
                        ],
                    ]
                }
            },
            //Sección de Totales
            {
                margin: [350, 10, 0, 0],
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [

                            { text: 'Subtotal:', alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(neto).format('0,0'), alignment: 'right', bold: true, border: [false, false, false, false] },
                        ],
                        [

                            { text: `Descuento: (${content.discount}%)`, alignment: 'left', border: [false, false, false, false] },
                            { text: `(${numeral(discount).format('0,0')})`, alignment: 'right', bold: true, border: [false, false, false, false] },
                        ],
                        [

                            { text: `Neto:`, alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(subTotal).format('0,0'), alignment: 'right', bold: true, border: [false, false, false, false] },
                        ],
                        [

                            { text: IVA + '% IVA:', alignment: 'left', border: [false, false, false, true] },
                            { text: numeral(totalIVA).format('0,0'), alignment: 'right', bold: true, border: [false, false, false, true] },
                        ],
                        [
                            { text: 'Total:', alignment: 'left', border: [false, false, false, true] },
                            { text: numeral(totalconIVA).format('0,0'), alignment: 'right', bold: true, border: [false, false, false, true] },
                        ],
                    ]
                }
            },
        ],

        footer: {
            columns: [{
                text: company.fantasy + ' - ' + company.webpage,
                alignment: "center",
                style: "footer"
            }]
        },
        images: {
            logo: company.logo_url
        },
        defaultStyle: {
            font: "RobotoCondensed",
            fontSize: 10,
            color: "#5B5A59"
        },
        styles: {
            h1: {
                font: "RobotoCondensed",
                fontSize: 20,
                bold: true,
                alignment: "center",
                color: "#7A3D8A"
            },
            h2: {
                font: "RobotoCondensed",
                fontSize: 12,
                bold: true,
                alignment: "left",
                color: "#5B5A59"
            },
            p: {
                font: "RobotoCondensed",
                fontSize: 10,
                lineHeight: 1.2,
                color: "#5B5A59"
            },
            reportHeader: {
                font: "RobotoCondensed",
                fontSize: 10,
                lineHeight: 1.5,
                color: "#5B5A59"
            },
            footer: {
                font: "RobotoCondensed",
                fontSize: 8,
                color: "#5B5A59"
            },
            table: {
                font: "RobotoCondensed",
                fontSize: 10,
                color: "#5B5A59"
            }
        }
    };
    pdfMake.createPdf(dd).open();
}

function buildItemsTableBody(data, stock) {
    var body = [];
    var tableData = [];
    const numberFormat = 'de-DE'
    const currencyFormat = 'CLP'

    //columnas que va a tomar del objeto
    const columns = ['item', 'name', 'qty', 'price', 'total']

    //Creando el Header de la Table
    body.push(['Item', 'Descripción', { text: 'Cantidad', alignment: 'right' }, { text: 'Precio Unit', alignment: 'right' }, { text: 'Valor', alignment: 'right' }]);

    //preparando la data para crear la tabla
    //Incluimos los productos
    data.map(item => {
        tableData.push({
            item: { text: data.indexOf(item) + 1, alignment: 'center', border: [false, false, false, true], rowSpan: item.notes ? 2 : 1, margin: [0, 3, 0, item.notes ? 0 : 3] },
            name: { text: stock ? `[${item.article_sku}] ${item.article_name}` : item.name, border: [false, false, false, item.notes ? false : true], margin: [0, 3, 0, item.notes ? 0 : 3] },
            qty: { text: item.qty, alignment: 'right', border: [false, false, false, item.notes ? false : true], margin: [0, 3, 0, item.notes ? 0 : 3] },
            price: { text: numeral(item.price).format('0,0.00'), alignment: 'right', border: [false, false, false, item.notes ? false : true], margin: [0, 3, 0, item.notes ? 0 : 3] },
            total: { text: numeral(parseInt(item.price * item.qty)).format('0,0'), alignment: 'right', border: [false, false, false, item.notes ? false : true], margin: [0, 3, 0, item.notes ? 0 : 3] },
        })
        if (item.notes) {
            tableData.push({
                item: { text: '', border: [false, false, false, true] },
                name: { colSpan: 4, text: 'Observaciones: ' + item.notes, border: [false, false, false, true], margin: [0, 3, 0, 3] },
                qty: '',
                price: '',
                total: ''
            })
        }
    })

    tableData.forEach(function(row) {
        var dataRow = [];
        columns.forEach(function(column) {
            dataRow.push(row[column]);
        });
        body.push(dataRow);
    });
    return body;
}