<template>
  <div>
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo">
          Rechazar cotizacion {{ salesNote.id }}
        </h1>
      </v-card-title>
      <v-card-text class="">
        <v-row>
          <v-col>
            <v-text-field
              v-model="salesNote.sales_note_customer.name"
              outlined
              dense
              readonly
              label="Cliente"
              filled
            />
            <v-text-field
              v-model="salesNote.name"
              outlined
              dense
              readonly
              label="Proyecto"
              filled
            />
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(()=>{showConfirm=true})">
                <ValidationProvider  name="Razon" rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="rejected_reason"
                      :items="rejected_reasons"
                      placeholder="Seleccione una razon"
                      outlined
                      dense
                      required
                      label="Razon de rechazo"
                      :error-messages="errors"
                    ></v-select>
                </ValidationProvider>
                <ValidationProvider name="Observaciones" rules="max:200" v-slot="{ errors }">
                    <v-textarea
                      v-model="rejected_note"
                      counter
                      label="Incluya sus observaciones"
                      outlined
                      dense
                      required
                      :error-messages="errors"
                      rows="3"
                    />
                </ValidationProvider>
                <v-col cols="12" class="text-center">
                  <v-btn
                    color="primary"
                    class="white--text"
                    type="submit"
                  >
                    Aceptar
                  </v-btn>
                  <v-btn color="primary" text @click="$emit('cancel')">
                    Cancelar
                  </v-btn>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <confirm v-model="showConfirm" :message="`¿Confirma rechazar la cotización #
    ${salesNote.id}? Este paso no se puede deshacer.`" @ok="reject()"
    @cancel="showConfirm = false"/>
  </div>
</template>

<script>
import { RejectSalesNote } from "@/api/salesNotes";
import notifications from "../../utils/notifications";

export default {
  name: "RejectSalesNote",
  props: ["salesNote"],
  data() {
    return {
      loading: false,
      rejected_reasons: [
        { text: "Precio", value: "price" },
        { text: "Fecha de entrega", value: "delivery_date" },
      ],
      rejected_reason: "price",
      rejected_note: "",
      showConfirm: false,
    };
  },
  methods: {
    async reject() {
      await RejectSalesNote(
        this.salesNote.id,{rejected_reason: this.rejected_reason, rejected_note: this.rejected_note}
      )
      .then((response) => {
        console.log(response)
        notifications.showSuccess("Cotización marcada como rechazada");
        this.$emit("refresh");
      })
      .catch((error) => {
        notifications.showError("Error al rechazar la cotización | " + error)
        this.showConfirm = false;

      });
    },
  },
};
</script>
